import { useLocation, WindowLocation } from "@reach/router";
import React from "react";
import Course from "../components/Course";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Statements from "../components/Statements";
import Studies from "../components/Studies";
import Treatment from "../components/Treatment";
import Home from "./Home";


//import {SendGridService} from "../api/SendgridService";

type IndexSiteProps = { location: WindowLocation }

export const IndexPage: React.FC<IndexSiteProps> = () => {
  const location = useLocation()

  return (
    <div className="bg-[#f9f9e9]">
      <Layout location={location}>
        <Seo title="Reiki Grad Wien Home" />

        <div className="px-4 lg:px-0 " id="portfolio">
          <Home />
          <Studies slide={true} />
          <Treatment slide={true} />
          <Course />
          <Statements />

          <div className="container mx-auto py-20"></div>
        </div>
      </Layout>
    </div>
  )
}

export default IndexPage

//https://medium.com/vowel-magic/how-to-fetch-data-from-google-sheets-with-react-and-tabletop-js-ca0e9d2ab34b

