import { WindowLocation } from "@reach/router";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";


type TitleProps = {
  location: WindowLocation
  site?: {
    siteMetadata: {
      exampleUrl: string
      logo: string
    }
  }
}



type IndexSiteProps = { location: WindowLocation }

const Statements: React.FC = () => {
  const data = useStaticQuery<TitleProps>(graphql`
    query {
      site {
        siteMetadata {
          contact {
            api_url
            description
            mail
            phone
            address
          }
        }
      }
    }
  `)


  const messages = [
    {
      type: 0,
      text: "Hello! Good Morning!",
    },
    {
      type: 1,
      text: "Hello! Good Afternoon!",
    },
  ]

  return (
    <>
      <div className="container mx-auto py-12">
        <div className="title py-12 text-center">
          <h2 className="font-black text-5xl text-color-1">Teilnehmer</h2>
        </div>
      </div>

      <div className="container mx-auto py-12">
        <div className="clearfix">
          <div className="bg-blue-200 float-right w-3/4 mx-4 my-2 p-2 rounded-lg clearfix">
            Reiki ist wie ein kleiner Urlaub für mich. Das sollte jeder kennen.
            <br></br>
            Rebecca S. 1160 Wien
          </div>
        </div>

        <div className="clearfix">
          <div className="bg-gray-300 float-left w-3/4 mx-4 my-2 p-2 rounded-lg clearfix">
            Ich hätte nicht gedacht, das ich das so spüren kann. Aber mhm, das
            fühlt sich gut an.<br></br> Anna O. 1140 Wien
          </div>
        </div>

        <div className="clearfix">
          <div className="bg-green-300 float-right w-3/4 mx-4 my-2 p-2 rounded-lg clearfix">
            Reiki ist mir seit vielen Jahren eine gute Stütze im Alltag.
          </div>
        </div>
      </div>
      <div className="container mx-auto py-20"></div>
    </>
  )
}

export default Statements
