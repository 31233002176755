import { useLocation } from "@reach/router";
import { PageProps } from "gatsby";
import * as React from "react";
import { MiddleEntry } from "../components/MiddleEntry";
import Seo from "../components/seo";
import forest from "../images/tree_creation.mp4";



type TitleProps = {
  site: {
    siteMetadata: {
      exampleUrl: string
    }
  }
}

const Home: React.FC = () => {
  const testpage = {
    title: "",
    description: () => {
      return (
        <div>
          Lass Energie durch deine Hände fließen:{" "}
          <strong>Entdecke Reiki!</strong> <br /> <br /> Reiki ist eine
          energetische Methode, die Anfang der 1920er Jahre von Mikao Usui in
          Japan entwickelt worden ist. Ziel der Methode ist es, einen
          energetischen Impuls zur „Verbesserung von Körper und Geist“ zu
          setzen. Reiki fällt in den Bereich der WKO Humanenergetik. Siehe auch:{" "}
          <a
            href="https://www.humanenergetiker.co.at/wp-content/uploads/2021/06/he-3-ebenen-model-maerz14-ansicht.pdf"
            className="underline"
          >
            {" "}
            Ebenen-Modell
          </a>{" "}
          <br /> Es wird in unterschiedliche Grade bzw. Stufen unterteilt. Ich habe über
          25 Jahren Erfahrung mit Reiki und biete dir an, Reiki in einem Seminar
          zu zeigen bzw. zu lernen. Wenn man sich darauf einlässt,
          kann es jeder.{" "}
        </div>
      )
    },
  }

  const location = useLocation()

  return (
    <div>
      <Seo title="Reiki Grad Wien entdecken" />
      <MiddleEntry
        data={testpage}
        key={`p-item-index-1`}
        even={true}
        slide={true}
      >
        <div className="h-30 pt-4">
          <video width="100%" height="100vh" preload="auto" loop autoPlay muted>
            <source src={forest} type="video/mp4"/>
            Your browser does not support HTML5 video.
          </video>
        </div>
      </MiddleEntry>
    </div>
  )
}

export default Home
