import { useLocation } from "@reach/router"
import { PageProps } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import { DateTable } from "../components/DateTable"

function sendMail() {
  var link =
    "mailto:di.lechner@gmail.com"
  window.location.href = link
}

const Course: React.FC = () => {
  const location = useLocation()
  return (
    <>
      <div className="container mx-auto py-12">
        <div className="title py-12 text-center">
          <h2 className="font-black text-5xl text-color-1">Kurse</h2>
        </div>
        <DateTable></DateTable>
        <div className="flex flex-wrap pb-5">
          Mein Name ist Christian Lechner. Ich habe mit 18 Jahren mit Reiki
          begonnen und meditiere auch seit dieser Zeit. Die Kurse finden im
          kleinen Rahmen im 3. Bezirk in Wien statt. Für Fragen und für ein
          gegenseitiges Kennenlernen gibt es ein Treffen vor dem Kurs an einem
          Abend. Beginn für den Kurs ist 9:00. Es gibt Kaffee, Yogi-Tee und
          Gebäck. Die Mittagspause kann man in einem der Lokale in der Umgebung
          verbringen. Kursende ist ca. 17:00. Bitte bequeme Kleidung und eine
          Decke mitbringen. Eine Stornierung ist bis 48h vor Kursbeginn möglich.
          Weitere Fragen können wir noch am Kennenlernabend
          klären.
          <br /> <strong>Die Kurskosten sind 290€.</strong> <br />
          
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div className="flex">
            <button
              className="btn btn-primary border-blue-500 bg-secondary"
              onClick={() => {
                sendMail()
                return false
              }}
            >
              Anmeldung per Email
            </button>
          </div>
          <div className="flex">
            <StaticImage
              src="../images/Kopf_2.png"
              width={300}
              quality={95}
              formats={["auto", "webp", "avif"]}
              alt="Reikigrad Kurs Anmeldung Leiter"
              style={{
                marginBottom: `1.45rem`,
                borderRadius: `10px`,
                justifyContent: `center`,
              }}
            />
          </div>
        </div>
        <div className="flex pb-10 text-center"></div>
      </div>
    </>
  )
}

export default Course
